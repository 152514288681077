import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M7930 7133 c-539 -3 -637 -5 -646 -17 -8 -11 -41 -16 -134 -19 -95
-3 -125 -7 -134 -19 -6 -9 -36 -18 -70 -22 -33 -5 -66 -14 -73 -22 -8 -8 -26
-14 -41 -14 -31 0 -68 -19 -42 -21 18 -2 -57 -20 -82 -20 -10 0 -20 -4 -23
-10 -4 -5 -15 -9 -26 -9 -10 0 -19 -4 -19 -10 0 -5 -7 -10 -16 -10 -10 0 -14
-6 -11 -14 4 -11 -3 -16 -25 -18 -24 -2 -32 -8 -35 -27 -2 -14 -8 -30 -14 -37
-7 -9 -3 -18 16 -32 14 -11 25 -24 25 -28 0 -15 56 -94 62 -88 4 3 28 -40 55
-95 36 -75 54 -103 73 -110 14 -5 18 -10 9 -10 -20 -1 -13 -61 7 -61 8 0 14
-9 14 -21 0 -11 4 -17 10 -14 7 4 9 -2 4 -19 -7 -29 7 -34 26 -11 7 8 9 15 5
15 -20 0 22 31 63 46 26 10 51 24 55 31 4 7 17 13 27 13 11 0 34 9 52 20 18
11 44 20 58 20 14 0 49 9 79 20 52 19 86 19 1049 20 548 0 998 2 1000 5 6 5
-82 120 -130 169 -21 22 -38 45 -38 50 0 6 -25 38 -56 70 -30 32 -61 71 -69
85 -7 14 -23 36 -34 48 -12 12 -21 27 -21 33 0 5 -4 10 -8 10 -4 0 -19 18 -32
40 -14 22 -28 40 -32 40 -5 0 -8 6 -8 13 0 25 -49 37 -143 35 -51 -2 -378 -4
-727 -5z m-1057 -140 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z
m-158 -55 c6 -4 2 -8 -7 -8 -10 0 -18 7 -18 17 0 9 3 12 8 7 4 -5 12 -12 17
-16z m-92 -55 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m97 -23
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-80 -100 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m68 -73 c15 -43 15 -47 -3 -47 -8 0 -15 4 -15 9 0 5 -3 23 -6 40 -9
41 9 40 24 -2z m80 -156 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9
7 -2 15 -10 17 -17z"/>
<path d="M6400 6610 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6400 6570 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6440 6570 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6481 5788 c-1 -445 -5 -744 -11 -746 -20 -7 16 -42 42 -42 21 0 28
-5 28 -20 0 -11 5 -20 10 -20 6 0 20 -10 33 -22 l22 -23 3 23 c2 12 10 22 18
22 11 0 13 -4 6 -17 -16 -28 14 -63 54 -63 22 0 34 -5 34 -14 0 -9 17 -16 46
-21 44 -6 123 -40 135 -57 3 -5 43 -13 89 -18 45 -5 86 -12 89 -16 3 -4 -10
-4 -29 -1 -26 5 -31 4 -18 -3 9 -6 36 -11 60 -11 96 -3 168 -11 163 -19 -3 -5
9 -11 27 -14 59 -9 1366 -13 1382 -4 9 5 16 15 16 22 0 7 16 30 36 51 20 21
47 57 61 79 14 23 52 69 84 103 33 34 59 67 59 74 0 6 17 30 38 53 36 41 84
98 119 145 10 13 26 32 35 42 17 18 -8 19 -980 21 -548 2 -1002 2 -1009 0 -6
-2 -9 -10 -6 -18 6 -17 -18 -19 -35 -2 -7 7 -12 22 -12 35 0 18 -6 23 -25 23
-13 0 -28 6 -32 13 -12 18 -60 37 -78 31 -10 -4 -15 0 -15 15 0 12 -8 25 -17
28 -10 4 -27 15 -37 25 -11 10 -26 18 -33 18 -7 0 -13 6 -13 14 0 7 -28 42
-62 77 -54 56 -62 69 -66 111 -3 34 -9 48 -20 48 -11 0 -13 -8 -8 -39 3 -22 9
-46 12 -55 3 -9 1 -16 -5 -16 -20 0 -30 46 -26 131 1 46 4 89 6 94 4 16 -13
89 -22 96 -5 3 -10 69 -11 147 -2 107 -6 142 -16 142 -7 0 -15 7 -18 15 -4 8
-10 12 -15 9 -5 -3 -9 2 -9 10 0 9 5 16 10 16 6 0 10 18 10 40 0 25 -5 40 -12
40 -9 0 -8 4 2 11 11 8 12 12 3 16 -7 2 -13 11 -13 19 0 8 -4 14 -10 14 -5 0
-10 11 -10 25 0 14 -5 25 -11 25 -7 0 -9 -10 -5 -25 4 -14 2 -25 -4 -25 -5 0
-10 24 -10 54 0 34 -5 58 -14 65 -12 10 -14 -94 -15 -731z m119 380 c0 -4 -9
-8 -20 -8 -11 0 -20 4 -20 8 0 4 9 8 20 8 11 0 20 -4 20 -8z m-13 -197 c3 -34
2 -51 -6 -51 -7 0 -11 22 -11 57 0 68 9 65 17 -6z m-10 -113 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m173 -323 c0 -8 -7 -15 -15 -15 -16 0
-20 12 -8 23 11 12 23 8 23 -8z m-230 -505 c0 -5 -9 -10 -20 -10 -11 0 -20 5
-20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m453 -237 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M6072 5575 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6265 5180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6345 5110 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M7040 4186 c0 -77 -4 -126 -10 -126 -5 0 -10 3 -10 8 0 4 -17 25 -38
47 -21 22 -64 73 -95 113 -52 64 -61 72 -92 72 l-35 0 0 -194 c0 -122 4 -197
10 -201 6 -4 24 -4 40 0 l30 7 0 119 c0 65 3 119 8 119 4 0 28 -26 54 -58 25
-31 58 -70 72 -87 15 -16 36 -45 49 -63 22 -33 51 -47 84 -40 17 3 18 17 18
203 l0 200 -42 3 -43 3 0 -125z"/>
<path d="M7224 4296 c-2 -6 -3 -97 -2 -201 l3 -190 150 0 150 0 3 32 3 32
-113 3 -113 3 -3 48 c-2 33 1 47 10 48 7 0 51 1 98 2 l85 2 0 30 0 30 -85 2
c-47 1 -91 2 -98 2 -9 1 -12 15 -10 49 l3 47 108 3 107 3 0 35 0 35 -146 -2
c-98 -1 -147 -6 -150 -13z"/>
<path d="M7729 4296 c-89 -31 -139 -100 -139 -189 0 -69 18 -113 63 -154 42
-38 86 -53 155 -53 59 0 139 30 149 56 3 8 -5 23 -18 33 -24 19 -24 19 -55 0
-36 -22 -107 -25 -143 -6 -43 22 -65 61 -63 118 2 79 37 123 108 134 36 6 77
-5 125 -32 3 -2 16 6 29 17 l23 20 -22 24 c-41 43 -139 58 -212 32z"/>
<path d="M7984 4296 c-3 -8 -4 -25 -2 -38 3 -21 9 -23 58 -25 30 -1 60 -2 65
-2 6 -1 10 -62 10 -163 l0 -163 42 -3 43 -3 2 168 3 168 58 -3 57 -4 0 41 0
41 -165 0 c-134 0 -167 -3 -171 -14z"/>
<path d="M5613 4293 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5917 4285 c-47 -16 -54 -22 -40 -36 13 -13 85 -20 77 -8 -3 5 17 6
43 2 105 -14 143 -51 143 -138 0 -43 -31 -124 -48 -125 -24 -2 -103 -29 -84
-29 13 -1 22 -7 22 -16 0 -8 5 -15 10 -15 6 0 10 6 10 14 0 8 5 18 10 21 7 4
9 -3 6 -18 -3 -20 -1 -25 13 -22 34 6 83 43 109 81 29 43 45 144 22 144 -7 0
-10 7 -6 16 8 21 -10 52 -52 92 -38 35 -36 35 -107 34 -47 0 -48 0 -15 7 32 7
30 8 -20 9 -31 0 -73 -6 -93 -13z m35 -16 c-24 -11 -49 -18 -55 -16 -7 3 -4 8
8 12 11 3 27 10 35 15 8 5 24 9 35 9 13 -1 4 -8 -23 -20z m145 -7 c-9 -2 -15
-8 -12 -13 4 -5 2 -9 -4 -9 -5 0 -13 5 -17 11 -9 15 15 29 35 21 14 -6 14 -7
-2 -10z m-97 -2 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16
-4 16 -10z m50 6 c0 -9 -30 -14 -35 -6 -4 6 3 10 14 10 12 0 21 -2 21 -4z
m100 -68 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10 10 -22z m7 -70
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m50 -60 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-56 -35 c-12 -20 -14 -14 -5 12 4 9 9
14 11 11 3 -2 0 -13 -6 -23z m14 -33 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0
6 2 10 4 10 3 0 8 -4 11 -10z m-40 -40 c-3 -5 -15 -10 -26 -10 -11 0 -17 5
-14 10 3 6 15 10 26 10 11 0 17 -4 14 -10z"/>
<path d="M6300 4105 l0 -195 41 0 40 0 -2 116 c-1 99 1 118 15 126 11 6 15 6
14 -1 -3 -10 10 -27 119 -160 69 -83 85 -95 113 -86 18 6 19 18 22 178 4 223
5 217 -38 217 -38 0 -36 9 -34 -130 0 -47 -4 -98 -9 -115 -5 -16 -10 -24 -10
-16 -1 12 -77 111 -128 166 -10 11 -31 37 -47 58 -23 30 -35 37 -62 37 l-34 0
0 -195z m47 179 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5480 4269 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5600 4270 c8 -5 24 -10 34 -10 26 0 18 6 -19 14 -22 5 -26 4 -15 -4z"/>
<path d="M5685 4267 c11 -8 29 -17 40 -20 17 -6 17 -4 -4 13 -13 11 -30 20
-39 20 -13 0 -13 -3 3 -13z"/>
<path d="M5847 4239 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5497 4233 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5677 4219 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5900 4220 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M5876 4206 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6 -12z"/>
<path d="M5810 4184 c-12 -30 -13 -54 -2 -39 4 6 14 11 22 13 8 2 17 8 18 13
8 22 22 7 17 -18 -5 -26 -5 -26 4 -5 12 25 6 48 -14 56 -9 3 -12 0 -9 -8 3 -7
-2 -19 -11 -26 -14 -12 -16 -10 -10 13 9 34 -2 35 -15 1z"/>
<path d="M5820 4106 c0 -20 2 -36 4 -36 8 0 12 61 4 66 -4 3 -8 -11 -8 -30z"/>
<path d="M5792 4105 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5851 4086 c-7 -9 -9 -16 -3 -16 5 0 13 -12 16 -26 10 -40 17 -22 8
21 -7 33 -9 36 -21 21z"/>
<path d="M5800 4046 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
<path d="M5841 4034 c-1 -19 36 -64 52 -64 16 0 5 18 -17 30 -14 7 -27 22 -29
34 -4 21 -4 21 -6 0z"/>
<path d="M5820 3996 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M5914 3974 c-2 -32 -13 -43 -23 -25 -5 7 -14 12 -21 9 -8 -2 -3 -11
14 -21 14 -10 26 -13 26 -7 0 5 8 10 18 10 10 0 27 7 38 15 19 14 19 14 0 15
-11 0 -27 6 -35 14 -14 13 -16 12 -17 -10z m26 -8 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M5670 3981 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M5703 3976 c9 -7 17 -16 17 -19 0 -3 -8 -7 -17 -9 -10 -2 -13 -6 -8
-10 6 -3 19 0 29 8 16 11 17 16 6 29 -7 8 -20 15 -29 15 -14 -1 -14 -2 2 -14z"/>
<path d="M5628 3963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5946 3925 c-17 -13 -16 -14 16 -20 19 -4 48 -5 64 -2 22 3 19 4 -14
6 -28 0 -41 5 -37 12 4 6 13 8 21 5 8 -3 14 -1 14 4 0 15 -42 11 -64 -5z"/>
<path d="M5573 3903 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
